/* Import font.css in App.css */
@import url('./font.css');


body {
  background-color: #18002f;
  color: #5D1FB9;

  font-family: Kanit-SemiBold, Kanit-SemiBold, Kanit-SemiBold;

  font-size: 15px;
  font-weight: normal;

  line-height: 1em;
  margin: 0;
}

.outer {
  padding: 10px;
}

.container {
  width: 80%;
  margin: auto;
  border: 5px #333333 solid;
  padding: 10px;
  border-radius: 25px;
}

.input {
  width: 1;
  padding: 8;
  margin: 10;
  width: 200;
}

.myform {
  padding: 20px;
}

.myform input[type="text"],
.myform textarea {
  padding: 8px;
  width: 100%;
}

.column {
  padding: 20px;
  float: left;
  width: 50%;
}

.column_aut {
  padding: 20px;
  float: left;
  width: 33%;
}

.Token_message {
  display: inline;
}

.swap {
  padding: 10px;
}

.swap_button {
  float: right;
}

.markdown {
  width: 80%;
  margin: auto;
}

.footer-text {
  color: rgb(220, 127, 251);
  text-decoration: none;
  padding:10px;
}

.footer-link {
  color: rgb(250, 228, 246);
  text-decoration: none;
  padding:1px;
}

/* footer-link hover */
.footer-link:hover {
  color: rgb(249, 5, 213);
  text-decoration: none;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}


.homepage{
  /*center the text*/
  text-align: center;
  /*set the text size*/
  font-size: 20px;
  /*set the text color*/
  color: #ffffff;

}