.Title {
  background: #18002f;
  height: 50px;
  text-align: center;
  font-size: 1rem;
  margin-top:65px;
}

.NavbarItems {
  background: #18002f;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  width: 100%;
  text-align: center;

}

.navbar-logo {
  color: #DAB2C4;
  justify-self: start;
  margin-left: 0px;
  cursor: pointer;
  
}

.logo {
  width: 230px;
  margin-top:65px;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 0px;
  list-style: none;
  text-align: center;
  justify-content: center;
  padding-top: 1rem;
  padding-left: 0rem;
  font-size: 25px;
}

.nav-links {
  color: rgb(243, 239, 239);
  text-decoration: none;
  padding:10px;
}


.nav-links-clicked {
  color: white;
  background-color: #551CA8;
  text-decoration: none;
  border-radius: 4px;
}

.nav-links:hover {
  color: rgb(249, 5, 213);
  text-decoration: none;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}


